import './Contact.css';

const Contact = () => {

    return(
        <div className="page">
            <h1 className="page-header">Contact</h1>

            <div className="inner-content">
                <div className="flex col f-sa">
                    <div className="content-full">
                        <h3>Bookings <span className="colon-fix">/</span> Inquiries <span className="colon-fix">/</span> General Contact</h3>
                        <p>Disclaimer: Abysmal Whore is not responsible for damaging your world or faith-based views. All complaints and woes will not be read and disregarded as we get that enough. We take no claim or responsibility for the fragile egos that can't handle the brutality of the real world. We hope you have a rotten day.</p>
                        <br/><br/>
                        <div id="contact-container">
                            <a id="contact" href="mailto:abysmalwhore@gmail.com">Abysmalwhore<span className="colon-fix">@</span>gmail.com</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Contact;