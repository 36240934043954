import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import 'react-image-lightbox/style.css';
import {Howl} from 'howler';
import './animations.css';
import './index.scss';
import './stars.css';
import App from './App';
import Nav from './Nav';
import About from './pages/About';
import Music from './pages/Music';
import Tour from './pages/Tour';
import Media from './pages/Media';
import Contact from './pages/Contact';
import NoPage from './pages/NoPage';

let page = window.location.href.split('/')[3];

// var sound = new Howl({
//   src: ['funeralloop.mp3'],
//   autoplay: true,
//   loop: true,
// });

//sound.play();
//document.getElementById('bod').click();

setTimeout(()=>{
  document.getElementById('logo').classList.remove('loaded');
  document.getElementById('loader').classList.add('hide');

  setTimeout(()=>{
    document.getElementById('bod').classList.remove('loaded');
  }, 5000);
},2000);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Nav />}>
          <Route index element={<App />} />
          <Route path="about" element={<About />} />
          <Route path="music" element={<Music />} />
          <Route path="shows" element={<Tour />} />
          <Route path="media" element={<Media />} />
          <Route path="contact" element={<Contact />} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);
if(page===''){
  page='home';
}
if(page!=null&&page!=='home'){
  document.getElementById('background').classList.add('active');
}else{
  document.getElementById('background').classList.remove('active');
}