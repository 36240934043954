import { Image } from "react-grid-gallery";


// export interface CustomImage extends Image {
//   original: string;
// }

export const images = [

  {
    src: "https://abysmalwhore.com/imgs/band1.jpg",
    original: "https://abysmalwhore.com/imgs/band1.jpg",
    width: 200,
    height: 200,
    //caption: "Boats (Jeshu John - designerspics.com)",
  },
  {
    src: "https://abysmalwhore.com/imgs/band2.jpg",
    original: "https://abysmalwhore.com/imgs/band2.jpg",
    width: 200,
    height: 200,
    //caption: "Boats (Jeshu John - designerspics.com)",
  },
  {
    src: "https://abysmalwhore.com/imgs/band3.jpg",
    original: "https://abysmalwhore.com/imgs/band3.jpg",
    width: 200,
    height: 200,
    //caption: "Boats (Jeshu John - designerspics.com)",
  },
  {
    src: "https://abysmalwhore.com/imgs/band4.jpg",
    original: "https://abysmalwhore.com/imgs/band4.jpg",
    width: 200,
    height: 200,
    //caption: "Boats (Jeshu John - designerspics.com)",
  },
  {
    src: "https://abysmalwhore.com/imgs/band5.jpg",
    original: "https://abysmalwhore.com/imgs/band5.jpg",
    width: 200,
    height: 200,
    //caption: "Boats (Jeshu John - designerspics.com)",
  },
  {
    src: "https://abysmalwhore.com/imgs/band6.png",
    original: "https://abysmalwhore.com/imgs/band6.png",
    width: 200,
    height: 200,
    //caption: "Boats (Jeshu John - designerspics.com)",
  },
  {
    src: "https://abysmalwhore.com/imgs/band7.png",
    original: "https://abysmalwhore.com/imgs/band7.png",
    width: 200,
    height: 200,
    //caption: "Boats (Jeshu John - designerspics.com)",
  },
  {
    src: "https://abysmalwhore.com/imgs/band8.png",
    original: "https://abysmalwhore.com/imgs/band8.png",
    width: 200,
    height: 200,
    //caption: "Boats (Jeshu John - designerspics.com)",
  },
  {
    src: "https://abysmalwhore.com/imgs/band9.png",
    original: "https://abysmalwhore.com/imgs/band9.png",
    width: 200,
    height: 200,
    //caption: "Boats (Jeshu John - designerspics.com)",
  },
  {
    src: "https://abysmalwhore.com/imgs/band10.png",
    original: "https://abysmalwhore.com/imgs/band10.png",
    width: 200,
    height: 200,
    //caption: "Boats (Jeshu John - designerspics.com)",
  },
  {
    src: "https://abysmalwhore.com/imgs/band11.png",
    original: "https://abysmalwhore.com/imgs/band11.png",
    width: 200,
    height: 200,
    //caption: "Boats (Jeshu John - designerspics.com)",
  },
  {
    src: "https://abysmalwhore.com/imgs/band12.png",
    original: "https://abysmalwhore.com/imgs/band12.png",
    width: 200,
    height: 200,
    //caption: "Boats (Jeshu John - designerspics.com)",
  },
  {
    src: "https://abysmalwhore.com/imgs/band13.png",
    original: "https://abysmalwhore.com/imgs/band13.png",
    width: 200,
    height: 200,
    //caption: "Boats (Jeshu John - designerspics.com)",
  },
  {
    src: "https://abysmalwhore.com/imgs/band14.png",
    original: "https://abysmalwhore.com/imgs/band14.png",
    width: 200,
    height: 200,
    //caption: "Boats (Jeshu John - designerspics.com)",
  },
  // {
  //   src: "",
  //   original: "",
  //   width: 200,
  //   height: 200,
  //   //caption: "Boats (Jeshu John - designerspics.com)",
  // },
];


