/* eslint-disable no-unused-vars */
import './Nav.scss';
import { Outlet, Link, NavLink } from "react-router-dom";
import burger from './assets/burger.svg';

let page = window.location.href.split('/')[3];
let mobilemenuOpen = false;

const Nav = (props) => {

    setTimeout(()=>{
        document.getElementById('main-nav').classList.add('visible');
    }, 7000);


    const setActiveNav = (e) => {
        if(page===''){
            page='home';
          }

        if(e === 'home'){
            page = 'home';
        }else{
            page = 'active';
        }
 
        if(page!='home'){
            document.getElementById('background').classList.add('active');
            //if(document.getElementById('logo-container')){
                document.getElementById('logo-container').classList.add('active');
                document.getElementById('main-nav').classList.add('active');
                document.getElementById('background-layer-1').classList.add('active');
            //}
        }else{
            document.getElementById('background').classList.remove('active');
            //if(document.getElementById('logo-container')){
                document.getElementById('logo-container').classList.remove('active');
                document.getElementById('main-nav').classList.remove('active');
                document.getElementById('background-layer-1').classList.remove('active');
            //}
        }
        document.getElementById('mobileshade').classList.remove('active');
        document.getElementById('main-nav').classList.remove('mobile');
        mobilemenuOpen = false;
    }
  
    setTimeout(()=>{
        if (page.length === 0){
            page='home';
        }
        setActiveNav(page);
    }, 6000);

    const toggleMobile = (e) => {
        if(!mobilemenuOpen){
            document.getElementById('mobileshade').classList.add('active');
            document.getElementById('main-nav').classList.add('mobile');
            mobilemenuOpen = true;
        }else{
            document.getElementById('mobileshade').classList.remove('active');
            document.getElementById('main-nav').classList.remove('mobile');
            mobilemenuOpen = false;
        }
    }

    const closeMobile = () => {
        document.getElementById('mobileshade').classList.remove('active');
        document.getElementById('main-nav').classList.remove('mobile');
        mobilemenuOpen = false;
    }

    return(
        <>
            <div id="logo-container">
                <NavLink onClick={() => setActiveNav('home')} to="/">
                    <img id="logo" className="loaded" src="./logo.svg" alt="logo" />
                </NavLink>
            </div>
            {/* 
                //This is for maintenance
                <nav id="main-nav">
                    <p id="comingsoon">Currently we are in hell doing abysmal stuff.</p>
                </nav> 
            */}
            <div id="burger" onClick={() => toggleMobile()}>
                <img src={burger} width={50} />
            </div>
            <div id="mobileshade" onClick={() => closeMobile()}></div>
            <nav id="main-nav" className={`${page!=null&&page!=='' ? 'active' : ''}`}>
                <ul>
                    <li><NavLink activeclassname="active" onClick={() => setActiveNav('pg')} to="/about">About</NavLink></li>
                    <li><NavLink activeclassname="active" onClick={() => setActiveNav('pg')} to="/music">Music</NavLink></li>
                    <li><NavLink activeclassname="active" onClick={() => setActiveNav('pg')} to="/shows">Shows</NavLink></li>
                    <li><NavLink activeclassname="active" onClick={() => setActiveNav('pg')} to="/media">Media</NavLink></li>
                    <li><Link target="_blank" to="https://abysmalwhore.bigcartel.com">Store</Link></li>
                    <li><NavLink activeclassname="active" onClick={() => setActiveNav('pg')} to="/contact">Contact</NavLink></li>
                </ul>
            </nav>

            <Outlet />
        </>
    );
}

export default Nav;