import { useEffect, useState } from 'react';
import './Media.css';
import { Gallery } from "react-grid-gallery";
import Lightbox from "react-image-lightbox";
import { images, CustomImage } from "./images";
//import { InstagramEmbed } from 'react-social-media-embed';
import { YouTubeEmbed } from 'react-social-media-embed';

const YOUTUBE_PLAYLIST_ITEMS_API = 'https://www.googleapis.com/youtube/v3/playlistItems';
const YOUTUBE_API_KEY = 'AIzaSyBgDqhKLnPYZNaKc4RmGu_SKlXbs6YDh6Q';


const Media = () => {
    const [vids, setVids] = useState([]);
    const [index, setIndex] = useState(-1);

    const currentImage = images[index];
    const nextIndex = (index + 1) % images.length;
    const nextImage = images[nextIndex] || currentImage;
    const prevIndex = (index + images.length - 1) % images.length;
    const prevImage = images[prevIndex] || currentImage;

    const handleClick = (index, item) => setIndex(index);
    const handleClose = () => setIndex(-1);
    const handleMovePrev = () => setIndex(prevIndex);
    const handleMoveNext = () => setIndex(nextIndex);

    const getVids = async () => {
        const res = await fetch(`${YOUTUBE_PLAYLIST_ITEMS_API}?part=snippet&playlistId=OLAK5uy_kANg6kLQLtfWRqenLAVzpDviohak79U5c&maxResults=50&key=${YOUTUBE_API_KEY}`);
        const data = await res.json();
        setVids(data.items)
    }

    useEffect(() => {
        getVids();
    },[]);

    return(
        <div className="page">
            <h1 className="page-header">Media</h1>

            <div className="inner-content">
                <div className="flex col f-sa">
                    <div className="content-full">
                        <h3>Images</h3>

                        <div>
                            <Gallery
                                images={images}
                                onClick={handleClick}
                                enableImageSelection={false}
                            />
                            {!!currentImage && (
                                /* @ts-ignore */
                                <Lightbox
                                mainSrc={currentImage.original}
                                imageTitle={currentImage.caption}
                                mainSrcThumbnail={currentImage.src}
                                nextSrc={nextImage.original}
                                nextSrcThumbnail={nextImage.src}
                                prevSrc={prevImage.original}
                                prevSrcThumbnail={prevImage.src}
                                onCloseRequest={handleClose}
                                onMovePrevRequest={handleMovePrev}
                                onMoveNextRequest={handleMoveNext}
                                />
                            )}
                        </div>
                        <br/><br/>

                        <h3>Videos</h3>

                        {vids?.length > 0
                            ? (
                                <div className="vid-container">
                                    {vids.map((vid, i) => (
                                        <div key={vid.snippet.resourceId.videoId} className="video-cont" style={{ display: 'flex', justifyContent: 'center' }}>
                                            <YouTubeEmbed className="video-item" url={`https://www.youtube.com/watch?v=${vid.snippet.resourceId.videoId}`} />
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <div className="empty">
                                    <h2>No movies found</h2>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>

            {/* <div style={{ display: 'flex', justifyContent: 'center' }}>
                <InstagramEmbed url="https://www.instagram.com/p/CUbHfhpswxt/" width={328} />
            </div> */}
            
        </div>
    );
}

export default Media;