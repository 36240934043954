import './App.css';
import React from 'react'

const App = () => {
    return(
        <div>
            
        </div>
    )

}

export default App;