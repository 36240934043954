import './Music.css';

const Music = () => {

    return(
        <div className="page">
            <h1 className="page-header">Music</h1>

            <div className="inner-content">
                <div className="flex col f-sa">
                    <div className="content-full bio">
                        <h3>Sadistic Urge</h3>

                        <iframe className="bandcamp-iframe" src="https://bandcamp.com/EmbeddedPlayer/album=1981987487/size=large/bgcol=333333/linkcol=e32c14/tracklist=false/transparent=true/" seamless><a href="https://abysmalwhore.bandcamp.com/album/sadistic-urge">Sadistic Urge by Abysmal Whore</a></iframe>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Music;