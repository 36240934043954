import './About.css';
import band from '../assets/AW-band.jpg'

const About = () => {

    return(
        <div className="page">
            <h1 className="page-header">About</h1>

            <div className="inner-content">
                <div className="flex col f-sa">
                    <div className="content-full bio">
                        <h3>Abysmal Whore<span className="colon-fix">:</span> Rising from the Abyss</h3>
                        <p>
                            Emerging from the shadowy corners of Toronto's metal underground, Abysmal Whore erupted onto the death metal scene in 2023, brandishing a sound as dark and unforgiving as the void itself. This quartet of sonic marauders conjures a relentless blend of old-school death metal, drenched in the grotesque and the arcane, echoing the primal chaos that birthed their infernal hymns.
                        </p>
                        <br/>
                    </div>
                    <div className="content-full">
                        <img src={band} alt="Abysmal Whore" id="band-img" />
                    </div>
                    <div className="content-full bio">
                        <br/>
                        <h4>
                            Formation and Vision
                        </h4>
                        <p>
                            Formed in the heart of Canada's heaviest city, Toronto, Abysmal Whore is the brainchild of four seasoned musicians, each bringing a wealth of experience and a shared vision of musical brutality. With Marta Chilina's bone-chilling vocals and venomous riffs leading the charge, Adam Clark's searing guitar work, Andrew Phillips' thundering bass lines, and Kaveh Afshar's unrelenting drumming, the band forges a sound that is both ruthlessly aggressive and hauntingly atmospheric.
                        </p>
                        <br/>
                        <h4>
                            Debut Release<span className="colon-fix">:</span> "Sadistic Urge"
                        </h4>
                        <p>
                            Their debut demo, "Sadistic Urge," unleashed on March 24, 2024, is a testament to their uncompromising approach to death metal. Across its four harrowing tracks, the demo delves into the abyss of primordial darkness, exploring themes of predation and the supernatural with a raw intensity that leaves listeners ensnared in its merciless grasp.
                        </p>
                        <br/>
                        <h4>
                            Unholy Congregation
                        </h4>
                        <p>
                            The lineup of Abysmal Whore reads like a roll call of the damned, each member a harbinger of sonic devastation:
                        </p>
                        <ul className="band-list">
                            <li>
                                <span>Marta Chilina:</span>
                                <p>Vocals and guitars, wielding a voice that cuts through the darkness with chilling precision.</p>
                            </li>
                            <li>
                                <span>Adam Clark:</span>
                                <p>Guitars, summoning riffs that are both savagely brutal and hypnotically intricate.</p>
                            </li>
                            <li>
                                <span>Andrew Phillips:</span>
                                <p>Bass, grounding the band’s ferocity with earth-shaking low-end thunder.</p>
                            </li>
                            <li>
                                <span>Kaveh Afshar:</span>
                                <p>Drums, driving the relentless pace with his unyielding, machine-like precision.</p>
                            </li>
                        </ul>
                        <p>
                            Together, they forge a symphony of the macabre, each note a step deeper into the abyss.
                        </p>
                        <br/>
                        <h4>
                            Future and Legacy
                        </h4>
                        <p>
                            Still in their nascent stage, Abysmal Whore stands as a beacon of hope for death metal purists, promising to carve their name into the annals of the genre with unholy fervor. Their commitment to delivering authentic and brutal death metal ensures they will continue to wreak havoc on stages and in studios, leaving a trail of destruction in their wake.
                            <br/><br/>
                            In a world where many bands soften their edges, Abysmal Whore remains unapologetically brutal, a testament to the enduring power of true death metal. Brace yourself for the oncoming storm of Abysmal Whore, as they continue to rise from the depths, ready to engulf all who dare to listen.
                            <br/><br/>
                            For more about their music and upcoming releases, delve into their <a href="https://abysmalwhore.bandcamp.com/" target="_blank">Bandcamp profile</a> and join their unholy legion on <a href="https://www.metal-archives.com/bands/Abysmal_Whore/3540541018" target="_blank">Encyclopaedia Metallum</a>.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default About;